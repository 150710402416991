import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, animateScroll as scroll } from "react-scroll" //ftw!

//images
import ECSLogo from "../images/ECS_Header_Logo.inline.svg"
function Header() {
  const [isExpanded, toggleExpansion] = useState(false)
  return (
    <div className="fixed md:sticky top-0 w-full md:w-1/6 z-40 bg-white">
      {/* Mobile Sticky Header */}
      <div className={`${isExpanded ? `hidden` : `flex`} md:hidden py-4 mx-10`}>
        <Link
          className="no-underline w-1/2 mt-3 -ml-6"
          to="/"
          onClick={() => scroll.scrollToTop()}
        >
          <ECSLogo alt="brand logo" className="block mx-auto w-full" />
        </Link>
        <button
          className="block absolute right-0 
            text-primary_color mx-5 h-16 w-16"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-10 w-16 float-right"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h10v2H0V3zm0 6h10v2H0V9zm0 6h10v2H0v-2z" />
          </svg>
        </button>
      </div>
      {/* Header - sidenav */}
      <header
        className={`${
          isExpanded ? `flex` : `hidden`
        } md:flex flex-col h-screen fixed md:sticky top-0 w-full z-10`}
      >
        <div
          className="hidden md:flex flex-row pl-4 pt-4 mt-12 -ml-14 mb-5 pb-5 md:mt-7 w-11/12"
          id="sidenav"
        >
          <Link
            className="no-underline cursor-pointer"
            to="/"
            onClick={() => scroll.scrollToTop()}
          >
            <ECSLogo alt="brand logo" className="block mx-auto w-full" />
          </Link>
        </div>
        <div className="flex md:hidden w-3/4 mx-10">
          <button
            className="block absolute top-0 right-0 my-7 mx-10 text-primary_color"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current float-right h-10 w-10"
              viewBox="0 0 96 96"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Close</title>
              <polygon points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 " />
            </svg>
          </button>
        </div>
        <div className="p-4 pt-40 md:pt-8 bg-white">
          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:flex flex-col h-screen`}
          >
            <ul
              className="grid grid-flow-row font-primary_font cursor-pointer text-3xl md:text-lg text-center
              text-heading_color md:text-left mainNav"
            >
              <li>
                <Link
                  to="intro"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  Intro
                </Link>
              </li>
              <li>
                <Link
                  to="using"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  Using Guidelines
                </Link>
              </li>
              <li>
                <Link
                  to="foundation"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  Brand Foundation
                </Link>
              </li>
              <li>
                <Link
                  to="messaging"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  Messaging
                </Link>
              </li>
              <li>
                <Link
                  to="logo"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  Logo Marks
                </Link>
              </li>
              <li>
                <Link
                  to="color"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  color
                </Link>
              </li>
              <li>
                <Link
                  to="typography"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  type
                </Link>
              </li>
              <li>
                <Link
                  to="photography"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  imagery
                </Link>
              </li>
              <li>
                <Link
                  to="expression"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  expression
                </Link>
              </li>
              <li>
                <Link
                  to="download"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  className=""
                  onClick={() => {
                    toggleExpansion(!isExpanded)
                  }}
                >
                  download
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="p-4 relative top-24">
          <p className="text-xs uppercase">v2.0 4/13/2022</p>
        </div>
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
